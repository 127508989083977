import React from 'react';
import { motion } from 'framer-motion';
import { Absolute, BoxProps } from 'pws-design-system/design-system';

const MotionBox = motion.custom(Absolute);

interface PositionMarkerProps extends BoxProps {
    animated?: boolean;
}

const Ring = ({ delay = 0 }: any) => (
    <MotionBox
        width="20px"
        height="20px"
        anchor="center"
        bg="brand.lightBlue.base"
        rounded="full"
        opacity={0.8}
        animate={{ width: 60, height: 60, opacity: 0 }}
        transition={{
            ease: [0.17, 0.67, 0.83, 0.67], duration: 1.5, delay, loop: Infinity,
        }}
    />
);

const PositionMarker = ({
    animated = true,
    ...rest
}: PositionMarkerProps) => (
    <Absolute
        width="40px"
        height="40px"
        anchor="center"
        {...rest}
    >
        {animated && (
            <Absolute
                width="40px"
                height="40px"
                anchor="center"
            >
                <Ring />
            </Absolute>
        )}
        <Absolute
            width="20px"
            height="20px"
            anchor="center"
            bg="brand.lightBlue.base"
            borderColor="white"
            borderWidth="3px"
            borderStyle="solid"
            rounded="full"
        />
    </Absolute>
);

export default PositionMarker;
