import React, { useLayoutEffect, useRef } from 'react';
import { Box, BoxProps } from 'pws-design-system/design-system';
import AerisWeather from '@aerisweather/javascript-sdk/dist/AerisWeather';
import { ICoordinate } from '@aerisweather/javascript-sdk/dist/interfaces/ICoordinate';
import { isset, isEmpty } from '@aerisweather/javascript-sdk/dist/utils';
import useDebouncedEffect from 'pws-design-system/design-system/hooks/useDebouncedEffect';

let n = 0;
const mapId = (): string => {
    n += 1;
    return `map-${n}`;
};

export enum MapType {
    Map = 'InteractiveMap',
    App = 'InteractiveMapApp'
}

const isValidCoord = ({ lat, lon }: { lat?: number, lon?: number } = {}): boolean => (
    isset(lat) && isset(lon) && Number.isNaN(lat) === false && Number.isNaN(lon) === false
);

export interface MapViewport {
    center: ICoordinate;
    zoom: number;
}

export interface MapProps extends BoxProps {
    type?: MapType;
    aerisId: string;
    aerisSecret: string;
    defaultCenter?: ICoordinate;
    defaultZoom?: number;
    center?: ICoordinate;
    zoom?: number;
    config?: any;
    interactionDisabled?: boolean;
    onMapReady?: (map: any, app: any) => void;
    onViewportChange?: (viewport: MapViewport) => void;
}

const Map = ({
    type = MapType.Map,
    aerisId,
    aerisSecret,
    defaultCenter,
    defaultZoom,
    center,
    zoom,
    config = {
        center: { lat: 40.0, lon: -96.5 },
        zoom: 3,
    },
    interactionDisabled = false,
    onMapReady = () => {},
    onViewportChange = () => {},
    ...rest
}: MapProps) => {
    const uid = useRef(mapId());
    const aeris = useRef(new AerisWeather(aerisId, aerisSecret));
    const mapEl = useRef(null);
    const component = useRef(null);
    const triggerBoundsChange = useRef(true);

    const mapConfig = { ...config };

    if (isValidCoord(defaultCenter)) {
        mapConfig.center = defaultCenter;
    }
    if (isset(defaultZoom) && Number.isNaN(defaultZoom) === false) {
        mapConfig.zoom = defaultZoom;
    }

    const handleBoundsChange = (e: any) => {
        const map = e.target;
        if (map && triggerBoundsChange.current === true) {
            const viewport = {
                center: map.getCenter(),
                zoom: map.getZoom(),
            };
            onViewportChange(viewport);
        }
        triggerBoundsChange.current = true;
    };

    const disableMapInteraction = (map: any) => {
        map.dragging.disable();
        map.doubleClickZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.scrollWheelZoom.disable();
        map.touchZoom.disable();
    };

    const updateViewportIfNeeded = () => {
        const map = component.current;
        if (!map || !center || isEmpty(center.lat) || isEmpty(center.lon)) {
            return;
        }

        const newCenter = { ...center };
        newCenter.lat = parseFloat(`${center.lat}`.replace(/[^0-9.-]/, ''));
        newCenter.lon = parseFloat(`${center.lon}`.replace(/[^0-9.-]/, ''));

        const c = map.getCenter();
        const z = map.getZoom();
        const viewportChanged = (isset(newCenter.lat) && isset(newCenter.lon) && (c.lat !== newCenter.lat || c.lon !== newCenter.lon)) || (isset(zoom) && zoom !== z);
        if (viewportChanged && isValidCoord(newCenter)) {
            triggerBoundsChange.current = false;
            if (isset(zoom)) {
                map.setView(newCenter, zoom);
            } else if (isset(defaultZoom)) {
                map.setView(newCenter, defaultZoom);
            } else {
                map.setCenter(newCenter);
            }
        }
    };

    useLayoutEffect(() => {
        async function initMap() {
            if (type === MapType.Map) {
                const views = await aeris.current.views();
                const view = new views.InteractiveMap(`#${uid.current}`, mapConfig);
                view.on('ready', () => {
                    component.current = view;
                    if (interactionDisabled) {
                        disableMapInteraction(view.map);
                    }
                    view.on('change:bounds', handleBoundsChange);
                    onMapReady(view, null);
                });
            } else if (type === MapType.App) {
                const apps = await aeris.current.apps();
                const view = new apps.InteractiveMapApp(`#${uid.current}`, mapConfig);
                view.map.on('ready', () => {
                    component.current = view;
                    if (interactionDisabled) {
                        disableMapInteraction(view.map);
                    }
                    view.map.on('change:bounds', handleBoundsChange);
                    onMapReady(view, null);
                });
            }
        }
        initMap();

        return () => {
            if (component.current) {
                component.current.off('change:bounds', handleBoundsChange);
                component.current = null;
            }
        };
    }, []);

    useDebouncedEffect(() => {
        updateViewportIfNeeded();
    }, 250, [center, zoom]);

    return (
        <Box {...rest}>
            <div style={{ width: '100%', height: '100%' }} ref={mapEl} id={uid.current} />
        </Box>
    );
};

export default Map;
